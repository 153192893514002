.text-black {
  color: #000000;
  text-shadow: 1px 1px #000000;
}

.text-darkblue {
  color: #0000AA;
  text-shadow: 1px 1px #00006A;
}

.text-darkgreen {
  color: #00AA00;
  text-shadow: 1px 1px #006A00;
}

.text-darkaqua {
  color: #00AAAA;
  text-shadow: 1px 1px #006A6A;
}

.text-darkred {
  color: #AA0000;
  text-shadow: 1px 1px #6A0000;
}

.text-darkpurple {
  color: #AA00AA;
  text-shadow: 1px 1px #6A006A;
}

.text-gold {
  color: #FFAA00;
  text-shadow: 1px 1px #BF6A00;
}

.text-gray {
  color: #999999;
  text-shadow: 1px 1px #595959;
}

.text-darkgray {
  color: #3f3f3f;
  text-shadow: 1px 1px #000000;
}

.text-blue {
  color: #5555FF;
  text-shadow: 1px 1px #1515BF;
}

.text-green {
  color: #55FF55;
  text-shadow: 1px 1px #15BF15;
}

.text-aqua {
  color: #55FFFF;
  text-shadow: 1px 1px #15BFBF;
}

.text-red {
  color: #FF5555;
  text-shadow: 1px 1px #BF1515;
}

.text-lightpurple {
  color: #FF55FF;
  text-shadow: 1px 1px #BF15BF;
}

.text-yellow {
  color: #FFFF55;
  text-shadow: 1px 1px #BFBF15;
}

.text-white {
  color: #FFFFFF;
  text-shadow: 1px 1px #BFBFBF;
}