*,
*::before,
*::after {
  box-sizing: border-box;
}

p {
  margin: 0;
}

table {
  margin-top: 0.5rem;
  tr, td {
    padding: 0 1.5rem;

    &:first-child {
      padding-left: 0;
    }
  }
}