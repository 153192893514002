@font-face {
  font-family: Minecraft;
  font-weight: 400;
  src: url('./../fonts/minecraft/normal.otf') format("otf")
}

@font-face {
  font-family: Minecraft;
  font-weight: 400;
  font-style: italic;
  src: url('./../fonts/minecraft/italic.otf') format("otf")
}

@font-face {
  font-family: Minecraft;
  font-weight: 600;
  src: url('./../fonts/minecraft/bold.otf') format("otf")
}

@font-face {
  font-family: Minecraft;
  font-weight: 600;
  font-style: italic;
  src: url('./../fonts/minecraft/bold-italic.otf') format("otf")
}